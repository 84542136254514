import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faCopy } from '@fortawesome/free-solid-svg-icons';

const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ShareButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #45a049;
  }
`;

const ShareInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
`;

const CopyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ShareLink = ({ boardId }) => {
  const [showLink, setShowLink] = useState(false);
  const shareUrl = `${window.location.origin}/${boardId}`;

  const handleShare = () => {
    setShowLink(true);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('Link copied to clipboard!');
    });
  };

  return (
    <ShareContainer>
      <ShareButton onClick={handleShare}>
        <FontAwesomeIcon icon={faLink} />
        Share Collaboard
      </ShareButton>
      {showLink && (
        <>
          <ShareInput value={shareUrl} readOnly />
          <CopyButton onClick={handleCopy}>
            <FontAwesomeIcon icon={faCopy} />
            Copy Link
          </CopyButton>
        </>
      )}
    </ShareContainer>
  );
};

export default ShareLink;