//Make it so the text box can be dragged at any place in the box if the text itself isn't selected (right now only the border can be dragged)

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import ColorPicker from './ColorPicker';
import ToolPicker from './ToolPicker';
import TextBox from './TextBox';
import UserCursor from './UserCursor';
import BrushSizeSlider from './BrushSizeSlider';
import TextFormatToolbar from './TextFormatToolbar';
import styled, { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import ShareLink from './ShareLink';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    overflow: hidden;
    background-color: var(--bg-color);
  }
`;

const CollaboardContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
`;

const ToolbarContainer = styled.div`
  position: fixed;
  top: 20px;
  left: ${(props) => (props.isOpen ? '20px' : '-280px')};
  width: 260px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease;
  overflow: hidden;
  z-index: 1000;
`;

const ToolbarToggle = styled.button`
  position: absolute;
  top: 50%;
  left: ${(props) => (props.isOpen ? '100%' : '260px')};
  transform: translateY(-50%);
  background-color: #4299e1;
  border: none;
  border-radius: 0 4px 4px 0;
  padding: 10px;
  cursor: pointer;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
  color: white;
  animation: ${(props) => (props.isOpen ? 'none' : 'bounce 2s infinite')};
  &:focus {
    outline: none;
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(-50%) translateX(0);
    }
    40% {
      transform: translateY(-50%) translateX(-5px);
    }
    60% {
      transform: translateY(-50%) translateX(-3px);
    }
  }
`;

const ToolbarContent = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ToolbarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToolbarTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  color: var(--text-color);
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: var(--text-color);
  &:focus {
    outline: none;
  }
`;

const Collaboard = () => {
  const { boardId } = useParams();
  const canvasRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [color, setColor] = useState('#000000');
  const [tool, setTool] = useState('pencil');
  const [brushSize, setBrushSize] = useState(2);
  const [textBoxes, setTextBoxes] = useState([]);
  const [newlyCreatedTextBoxId, setNewlyCreatedTextBoxId] = useState(null);
  const [otherUserPreviews, setOtherUserPreviews] = useState({});
  const [otherUserSelections, setOtherUserSelections] = useState({});
  const [userId, setUserId] = useState(null);
  const [userColor, setUserColor] = useState(null);
  const [userCursors, setUserCursors] = useState({});
  const [isCreatingTextBox, setIsCreatingTextBox] = useState(false);
  const [newTextBoxStart, setNewTextBoxStart] = useState(null);
  const [newTextBoxPreview, setNewTextBoxPreview] = useState(null);
  const [lastPosition, setLastPosition] = useState(null);
  const [isToolbarOpen, setIsToolbarOpen] = useState(true);
  const [selectedTextBox, setSelectedTextBox] = useState(null);
  const [textFormatOptions, setTextFormatOptions] = useState({
    font: 'Arial',
    size: '14px',
    color: '#000000',
    bold: false,
    italic: false,
    underline: false,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: '#000000',
    lineHeight: '1',
    letterSpacing: '1',
  });

  const textBoxRefs = useRef({});
  const toolbarRef = useRef(null);



  const toggleToolbar = () => {
    setIsToolbarOpen(!isToolbarOpen);
  };

  useEffect(() => {
    if (newlyCreatedTextBoxId) {
      const timer = setTimeout(() => {
        setNewlyCreatedTextBoxId(null);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [newlyCreatedTextBoxId]);

  useEffect(() => {
    const newSocket = io('https://collaboard.onrender.com', {
      withCredentials: true,
      transports: ['websocket'],
    });
    setSocket(newSocket);

    newSocket.emit('joinBoard', boardId);

    newSocket.on('userInfo', ({ userId, color }) => {
      setUserId(userId);
      setUserColor(color);
    });

    newSocket.on('initBoard', (boardData) => {
      if (boardData.drawings) {
        boardData.drawings.forEach((drawData) => drawOnCanvas(drawData));
      }
      if (boardData.textBoxes) {
        setTextBoxes(boardData.textBoxes);
      }
    });

    return () => newSocket.close();
  }, [boardId]);

  useEffect(() => {
    if (!socket) return;

    socket.on('draw', (data) => {
      drawOnCanvas(data);
    });

    socket.on('addTextBox', (textBox) => {
      setTextBoxes((prevTextBoxes) => [...prevTextBoxes, textBox]);
    });

    socket.on('updateTextBox', (updatedTextBox) => {
      setTextBoxes((prevTextBoxes) =>
        prevTextBoxes.map((tb) => (tb.id === updatedTextBox.id ? { ...tb, ...updatedTextBox } : tb))
      );
    });

    socket.on('deleteTextBox', (textBoxId) => {
      setTextBoxes((prevTextBoxes) => prevTextBoxes.filter((tb) => tb.id !== textBoxId));
    });

    socket.on('cursorMove', (data) => {
      setUserCursors((prev) => ({
        ...prev,
        [data.userId]: { x: data.x, y: data.y, color: data.color, name: data.name, tool: data.tool },
      }));
    });

    socket.on('textUpdate', (data) => {
      setTextBoxes((prevTextBoxes) =>
        prevTextBoxes.map((tb) =>
          tb.id === data.id ? { ...tb, content: data.content, formatOptions: data.formatOptions } : tb
        )
      );
    });

    socket.on('textBoxPreview', ({ userId, preview }) => {
      setOtherUserPreviews((prev) => ({ ...prev, [userId]: preview }));
    });

    socket.on('clearTextBoxPreview', ({ userId }) => {
      setOtherUserPreviews((prev) => {
        const newPreviews = { ...prev };
        delete newPreviews[userId];
        return newPreviews;
      });
    });

    socket.on('textBoxSelect', ({ userId, textBoxId }) => {
      if (userId !== socket.id) {
        setOtherUserSelections((prev) => ({ ...prev, [userId]: textBoxId }));
      }
    });

    socket.on('textBoxDeselect', ({ userId }) => {
      setOtherUserSelections((prev) => {
        const newSelections = { ...prev };
        delete newSelections[userId];
        return newSelections;
      });
    });

    socket.on('userDisconnected', (disconnectedUserId) => {
      setUserCursors((prevCursors) => {
        const newCursors = { ...prevCursors };
        delete newCursors[disconnectedUserId];
        return newCursors;
      });
    });

    return () => {
      socket.off('draw');
      socket.off('addTextBox');
      socket.off('updateTextBox');
      socket.off('deleteTextBox');
      socket.off('cursorMove');
      socket.off('textUpdate');
      socket.off('textBoxPreview');
      socket.off('clearTextBoxPreview');
      socket.off('textBoxSelect');
      socket.off('textBoxDeselect');
      socket.off('userDisconnected');
    };
  }, [socket]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      context.putImageData(imageData, 0, 0);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const draw = useCallback(
    (e) => {
      if (!isDrawing || tool === 'text') return;

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const drawData = {
        tool,
        color,
        brushSize,
        startX: lastPosition.x,
        startY: lastPosition.y,
        endX: x,
        endY: y,
        boardId,
      };

      socket.emit('draw', drawData);
      drawOnCanvas(drawData);
      setLastPosition({ x, y });
    },
    [isDrawing, tool, color, brushSize, socket, boardId, lastPosition]
  );

  const startDrawing = useCallback(
    (e) => {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      if (tool === 'text') {
        setIsCreatingTextBox(true);
        setNewTextBoxStart({ x, y });
      } else {
        setIsDrawing(true);
        setLastPosition({ x, y });
        draw(e);
      }
    },
    [tool, draw]
  );

  const handleTextBoxDeselect = useCallback(() => {
    setSelectedTextBox(null);
    socket.emit('textBoxDeselect', { boardId, userId });
  }, [socket, boardId, userId]);

  const handleCanvasClick = useCallback(
    (e) => {
      if (e.target === canvasRef.current) {
        handleTextBoxDeselect();
      }
    },
    [handleTextBoxDeselect]
  );

  const drawOnCanvas = (data) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const { tool, color, brushSize, startX, startY, endX, endY } = data;

    context.strokeStyle = color;
    context.lineWidth = brushSize;
    context.lineCap = 'round';
    context.lineJoin = 'round';

    context.beginPath();
    context.moveTo(startX, startY);
    context.lineTo(endX, endY);
    context.stroke();
  };

  const handleTextBoxSelect = useCallback(
    (textBoxId) => {
      setSelectedTextBox(textBoxId);
      socket.emit('textBoxSelect', { boardId, userId, textBoxId });
    },
    [socket, boardId, userId]
  );

  const handleMouseMove = useCallback(
    (e) => {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      if (socket && userId) {
        socket.emit('cursorMove', {
          boardId,
          userId,
          x,
          y,
          color: userColor,
          name: `User ${userId.slice(0, 4)}`,
          tool,
        });
      }

      if (isDrawing) {
        draw(e);
      } else if (isCreatingTextBox && newTextBoxStart) {
        const width = Math.abs(x - newTextBoxStart.x);
        const height = Math.abs(y - newTextBoxStart.y);
        const newX = Math.min(x, newTextBoxStart.x);
        const newY = Math.min(y, newTextBoxStart.y);

        setNewTextBoxPreview({ x: newX, y: newY, width, height });
        socket.emit('textBoxPreview', { boardId, userId, preview: { x: newX, y: newY, width, height } });
      }
    },
    [socket, userId, userColor, boardId, isDrawing, draw, isCreatingTextBox, newTextBoxStart, tool]
  );

  const stopDrawing = useCallback(
    (e) => {
      if (isCreatingTextBox && newTextBoxStart) {
        const rect = canvasRef.current.getBoundingClientRect();
        const endX = e.clientX - rect.left;
        const endY = e.clientY - rect.top;

        const newTextBoxId = Date.now().toString();
        const newTextBox = {
          id: newTextBoxId,
          content: '',
          x: Math.min(newTextBoxStart.x, endX),
          y: Math.min(newTextBoxStart.y, endY),
          width: Math.abs(endX - newTextBoxStart.x),
          height: Math.abs(endY - newTextBoxStart.y),
          formatOptions: textFormatOptions,
        };

        setTextBoxes((prev) => [...prev, newTextBox]);
        setNewlyCreatedTextBoxId(newTextBoxId);

        socket.emit('addTextBox', { boardId, textBox: newTextBox });
        socket.emit('clearTextBoxPreview', { boardId, userId });

        socket.emit('textBoxSelect', { boardId, userId, textBoxId: newTextBoxId });
        e.stopPropagation();
        handleTextBoxSelect(newTextBoxId);
      }

      setIsDrawing(false);
      setIsCreatingTextBox(false);
      setNewTextBoxStart(null);
      setNewTextBoxPreview(null);
    },
    [isCreatingTextBox, newTextBoxStart, textFormatOptions, socket, boardId, userId, handleTextBoxSelect]
  );

  const updateTextBox = useCallback(
    (id, updates) => {
      setTextBoxes((prev) =>
        prev.map((tb) => {
          if (tb.id === id) {
            const updatedTextBox = { ...tb, ...updates };
            socket.emit('updateTextBox', { boardId, textBox: updatedTextBox });
            return updatedTextBox;
          }
          return tb;
        })
      );
    },
    [boardId, socket]
  );

  const handleTextBoxContentChange = useCallback(
    (id, newContent) => {
      setTextBoxes((prev) =>
        prev.map((tb) => {
          if (tb.id === id) {
            const updatedTextBox = { ...tb, content: newContent };
            // Debounce the socket emission to prevent excessive re-renders
            socket.emit('textUpdate', {
              boardId,
              id,
              content: newContent,
              userId,
              formatOptions: updatedTextBox.formatOptions,
            });
            return updatedTextBox;
          }
          return tb;
        })
      );
    },
    [boardId, socket, userId]
  );

  const deleteTextBox = useCallback(
    (id) => {
      setTextBoxes((prev) => prev.filter((tb) => tb.id !== id));
      setSelectedTextBox(null);
      socket.emit('deleteTextBox', { boardId, textBoxId: id });
    },
    [boardId, socket]
  );

  const handleTextFormatChange = useCallback(
    (newOptions) => {
      setTextFormatOptions((prevOptions) => {
        const updatedOptions = { ...prevOptions, ...newOptions };
        if (selectedTextBox) {
          updateTextBox(selectedTextBox, { formatOptions: updatedOptions });

          // Focus the text area of the selected text box
          const textBoxRef = textBoxRefs.current[selectedTextBox];
          if (textBoxRef && textBoxRef.focusTextArea) {
            textBoxRef.focusTextArea();
          }
        }
        return updatedOptions;
      });
    },
    [selectedTextBox, updateTextBox]
  );


  const getSelectedTextBoxFormatOptions = useCallback(() => {
    if (selectedTextBox) {
      const selectedBox = textBoxes.find((box) => box.id === selectedTextBox);
      return selectedBox ? selectedBox.formatOptions : textFormatOptions;
    }
    return textFormatOptions;
  }, [selectedTextBox, textBoxes, textFormatOptions]);

  return (
    <>
      <GlobalStyle />
      <ToolbarContainer isOpen={isToolbarOpen} ref={toolbarRef}>
        <ToolbarToggle onClick={toggleToolbar} isOpen={isToolbarOpen}>
          {isToolbarOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faPencilAlt} />}
        </ToolbarToggle>
        {isToolbarOpen && (
          <ToolbarContent>
            <ToolbarHeader>
              <ToolbarTitle>{tool === 'text' ? 'Text Options' : 'Drawing Options'}</ToolbarTitle>
              <CloseButton onClick={toggleToolbar}>
                <FontAwesomeIcon icon={faTimes} />
              </CloseButton>
            </ToolbarHeader>
            <ColorPicker color={color} setColor={setColor} />
            <ToolPicker tool={tool} setTool={setTool} />
            {tool !== 'text' && <BrushSizeSlider brushSize={brushSize} setBrushSize={setBrushSize} />}
            {(tool === 'text' || selectedTextBox) && (
              <TextFormatToolbar
              options={getSelectedTextBoxFormatOptions()}
              onChange={handleTextFormatChange}
              focusTextArea={() => {
                const textBoxRef = textBoxRefs.current[selectedTextBox];
                if (textBoxRef && textBoxRef.focusTextArea) {
                  textBoxRef.focusTextArea();
                }
              }}
            />
            )}
            <ShareLink boardId={boardId} />
          </ToolbarContent>
        )}
      </ToolbarContainer>
      <CollaboardContainer onMouseMove={handleMouseMove} onClick={handleCanvasClick}>
        <Canvas
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseUp={stopDrawing}
          onMouseOut={stopDrawing}
        />
        {textBoxes.map((textBox) => (
          <TextBox
            key={textBox.id}
            ref={(el) => {
              textBoxRefs.current[textBox.id] = el;
            }}
            {...textBox}
            onUpdate={updateTextBox}
            onContentChange={handleTextBoxContentChange}
            onDelete={deleteTextBox}
            isSelected={
              selectedTextBox === textBox.id ||
              Object.values(otherUserSelections).includes(textBox.id)
            }
            selectedBy={
              Object.entries(otherUserSelections).find(([, id]) => id === textBox.id)?.[0]
            }
            onSelect={handleTextBoxSelect}
            onDeselect={handleTextBoxDeselect}
            socket={socket}
            userId={userId}
            userColor={userColor}
            boardId={boardId}
            formatOptions={textBox.formatOptions || textFormatOptions}
            userCursors={userCursors}
            isNewlyCreated={textBox.id === newlyCreatedTextBoxId}
            tool={tool}
            toolbarRef={toolbarRef}
          />
          
        ))}
        {Object.entries(otherUserPreviews).map(([userId, preview]) => (
          <div
            key={userId}
            style={{
              position: 'absolute',
              left: preview.x,
              top: preview.y,
              width: preview.width,
              height: preview.height,
              border: `2px dashed ${userCursors[userId]?.color || '#000'}`,
              pointerEvents: 'none',
            }}
          />
        ))}
        {Object.entries(userCursors).map(([id, cursor]) => (
          <UserCursor key={id} {...cursor} isSelectingTextBox={id === userId && selectedTextBox !== null} />
        ))}
        {newTextBoxPreview && (
          <div
            style={{
              position: 'absolute',
              left: newTextBoxPreview.x,
              top: newTextBoxPreview.y,
              width: newTextBoxPreview.width,
              height: newTextBoxPreview.height,
              border: `2px dashed ${userColor}`,
              pointerEvents: 'none',
            }}
          />
        )}
      </CollaboardContainer>
    </>
  );
};

export default Collaboard;
