import React from 'react';
import styled from 'styled-components';

const ColorPickerContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ColorButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${props => props.selected ? '#000' : 'transparent'};
  background-color: ${props => props.color};
  cursor: pointer;
`;

const ColorPicker = ({ color, setColor }) => {
  const colors = ['#000000', '#FF5733', '#33FF57', '#3357FF', '#FF33F1'];

  return (
    <ColorPickerContainer>
      {colors.map((c) => (
        <ColorButton
          key={c}
          color={c}
          selected={c === color}
          onClick={() => setColor(c)}
        />
      ))}
    </ColorPickerContainer>
  );
};

export default ColorPicker;