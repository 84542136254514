import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPaintBrush, faFont } from '@fortawesome/free-solid-svg-icons';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 0.9;
  }
`;

const CursorContainer = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 1000;
  transition: transform 0.1s ease;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  transform: translate(-50%, -50%);
`;

const CursorIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${props => (props.isSelectingTextBox ? pulse : 'none')} 1s infinite;
  transition: transform 0.2s ease;
`;

const UserName = styled.span`
  background-color: ${props => props.color};
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
`;

const UserCursor = React.memo(({ x, y, color, name, isSelectingTextBox, tool }) => {
  const getToolIcon = () => {
    switch (tool) {
      case 'pencil':
        return faPencilAlt;
      case 'brush':
        return faPaintBrush;
      case 'text':
        return faFont;
      default:
        return faPencilAlt;
    }
  };

  return (
    <CursorContainer x={x} y={y}>
      <CursorIcon color={color} isSelectingTextBox={isSelectingTextBox}>
        <FontAwesomeIcon icon={getToolIcon()} color="white" size="sm" />
      </CursorIcon>
      <UserName color={color}>{name}</UserName>
    </CursorContainer>
  );
});

export default UserCursor;
