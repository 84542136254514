import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;

  a {
    color: #4299e1;
    text-decoration: none;
    font-weight: bold;
  }
`;

const Footer = () => (
  <FooterContainer>
    Made by <a href="https://ajshulman.com" target="_blank" rel="noopener noreferrer">A.J. Shulman</a>
  </FooterContainer>
);

export default Footer;
