import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--bg-color);
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  color: var(--text-color);
  margin-bottom: 2rem;
`;

const Button = styled(motion.button)`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  margin: 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: var(--accent-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--accent-color-dark);
  }
`;

const Input = styled(motion.input)`
  padding: 1rem;
  font-size: 1.2rem;
  margin: 0.5rem;
  border: 1px solid var(--accent-color);
  border-radius: 5px;
  color: var(--text-color);
  background-color: white;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--accent-color);
  }
`;

// Add a styled-component for the alert
const Alert = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f56565; /* Red background */
  color: white;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeInOut 4s forwards;

  @keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

function LandingPage() {
  const [joinCode, setJoinCode] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (location.state && location.state.boardNotFound) {
      setShowAlert(true);
      // Clear the state to prevent showing the alert again on back navigation
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  // Function to hide the alert after a certain time
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 4000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const createNewBoard = () => {
    const newBoardId = Math.random().toString(36).substr(2, 9);
    navigate(`/${newBoardId}`);
  };

  const joinBoard = () => {
    if (joinCode.trim()) {
      navigate(`/${joinCode}`);
    }
  };

  return (
    <LandingContainer>
      <Title
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Welcome to Fluidea
      </Title>
      <Button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={createNewBoard}
      >
        Create New Collaboard
      </Button>
      <Input
        type="text"
        placeholder="Enter Collaboard Code"
        value={joinCode}
        onChange={(e) => setJoinCode(e.target.value)}
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      />
      <Button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={joinBoard}
      >
        Join Collaboard
      </Button>
      {showAlert && <Alert>Board not found</Alert>}
    </LandingContainer>
  );
}

export default LandingPage;
