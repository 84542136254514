import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Collaboard from './components/Collaboard';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBold, faItalic, faUnderline, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faPencilAlt, faPaintBrush, faFont, faLink, faCopy } from '@fortawesome/free-solid-svg-icons';
import Footer from './components/Footer';

library.add(faBold, faItalic, faUnderline, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faPencilAlt, faPaintBrush, faFont, faLink, faCopy);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/:boardId" element={<Collaboard />} />
          {/* <Route path="*" element={<NotFound />} /> */}
          
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;