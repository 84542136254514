import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPaintBrush, faFont } from '@fortawesome/free-solid-svg-icons';

const ToolPickerContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ToolButton = styled.button`
  padding: 8px;
  background-color: ${props => props.active ? '#e0e0e0' : 'white'};
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ToolPicker = ({ tool, setTool }) => {
  const tools = [
    { name: 'pencil', icon: faPencilAlt },
    { name: 'brush', icon: faPaintBrush },
    { name: 'text', icon: faFont },
  ];

  return (
    <ToolPickerContainer>
      {tools.map((t) => (
        <ToolButton
          key={t.name}
          active={(t.name === tool) ? 1 : 0}
          onClick={() => setTool(t.name)}
        >
          <FontAwesomeIcon icon={t.icon} />
        </ToolButton>
      ))}
    </ToolPickerContainer>
  );
};

export default ToolPicker;