import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import styled from 'styled-components';

const TextBoxContainer = styled.div`
  position: absolute;
  background-color: transparent;
  border: ${(props) =>
    `${props.$formatOptions?.borderWidth || 1}px ${
      props.$formatOptions?.borderStyle || 'solid'
    } ${props.$formatOptions?.borderColor || '#000000'}`};
  padding: 5px;
  cursor: ${(props) => (props.$isEditing ? 'text' : 'move')};
  user-select: none;
  overflow: hidden;
  box-shadow: ${(props) =>
    props.$isSelected ? `0 0 0 2px ${props.$selectionColor}` : 'none'};
  transition: box-shadow 0.2s ease;
  pointer-events: ${(props) => (props.$isInteractive ? 'auto' : 'none')};
`;

const TextArea = styled.div`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  background: transparent;
  font-family: ${(props) => props.$formatOptions?.font || 'Arial'};
  font-size: ${(props) => props.$formatOptions?.size || '14px'};
  color: ${(props) => props.$formatOptions?.color || '#000000'};
  font-weight: ${(props) => (props.$formatOptions?.bold ? 'bold' : 'normal')};
  font-style: ${(props) => (props.$formatOptions?.italic ? 'italic' : 'normal')};
  text-decoration: ${(props) =>
    props.$formatOptions?.underline ? 'underline' : 'none'};
  text-align: ${(props) => props.$formatOptions?.align || 'left'};
  line-height: ${(props) => props.$formatOptions?.lineHeight || 'normal'};
  letter-spacing: ${(props) => `${props.$formatOptions?.letterSpacing || 0}px`};
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  cursor: ${(props) => (props.$isEditing ? 'text' : 'inherit')};
  pointer-events: ${(props) => (props.$isEditing ? 'auto' : 'none')};
`;

const ResizeHandle = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #4299e1;
  border: 2px solid white;
  border-radius: 50%;
`;

const TextBox = forwardRef(
  (
    {
      id,
      content,
      x,
      y,
      width,
      height,
      onUpdate,
      onContentChange,
      onDelete,
      isSelected,
      onSelect,
      socket,
      userId,
      userColor,
      boardId,
      formatOptions,
      selectedBy,
      userCursors,
      onDeselect,
      isNewlyCreated,
      tool,
      toolbarRef,
    },
    ref
  ) => {
    const selectionColor = selectedBy ? userCursors[selectedBy]?.color : userColor;
    const [isEditing, setIsEditing] = useState(isNewlyCreated);
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [resizeDirection, setResizeDirection] = useState(null);
    const textAreaRef = useRef(null);

  const defaultFormatOptions = {
    font: 'Arial',
    size: '14px',
    color: '#000000',
    bold: false,
    italic: false,
    underline: false,
    align: 'left',
    lineHeight: 'normal',
    letterSpacing: '0',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: '#000000',
  };

   const currentFormatOptions = { ...defaultFormatOptions, ...formatOptions };

    // Function to focus the text area
    const focusTextArea = () => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    };

    // Expose focusTextArea to parent via ref
    useImperativeHandle(ref, () => ({
      focusTextArea,
    }));

    useEffect(() => {
      if (isNewlyCreated) {
        focusTextArea();
      }
    }, [isNewlyCreated]);

    useEffect(() => {
      if (textAreaRef.current && !isEditing) {
        textAreaRef.current.innerHTML = content;
      }
    }, [content, isEditing]);

  useEffect(() => {
    const handleContentUpdate = (updatedTextBox) => {
      if (updatedTextBox.id === id && updatedTextBox.userId !== userId) {
        if (textAreaRef.current) {
          if (!isEditing) {
            textAreaRef.current.innerHTML = updatedTextBox.content;
          }
          onUpdate(id, { formatOptions: updatedTextBox.formatOptions }, false);
        }
      }
    };

    socket.on('textUpdate', handleContentUpdate);

    return () => {
      socket.off('textUpdate', handleContentUpdate);
    };
  }, [socket, id, userId, onUpdate, isEditing]);

  const handleMouseDown = useCallback(
    (e) => {
      e.stopPropagation();
      if (!isEditing) {
        onSelect(id);
        setIsDragging(true);
        setDragStart({ x: e.clientX - x, y: e.clientY - y });
      }
    },
    [id, onSelect, x, y, isEditing]
  );

  const handleBlur = useCallback(
    (e) => {
      setTimeout(() => {
        const activeElement = document.activeElement;
        if (
          activeElement &&
          (activeElement === textAreaRef.current ||
            (toolbarRef.current && toolbarRef.current.contains(activeElement)))
        ) {
          // Focus is still within the text box or toolbar
          return;
        }
        setIsEditing(false);
        onDeselect();
      }, 0);
    },
    [onDeselect, toolbarRef]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        const newX = e.clientX - dragStart.x;
        const newY = e.clientY - dragStart.y;
        onUpdate(id, { x: newX, y: newY });
      } else if (isResizing) {
        const deltaX = e.clientX - dragStart.x;
        const deltaY = e.clientY - dragStart.y;
        let newWidth = dragStart.width;
        let newHeight = dragStart.height;
        let newX = x;
        let newY = y;

        if (resizeDirection.includes('e')) {
          newWidth = Math.max(50, dragStart.width + deltaX);
        }
        if (resizeDirection.includes('s')) {
          newHeight = Math.max(50, dragStart.height + deltaY);
        }
        if (resizeDirection.includes('w')) {
          newWidth = Math.max(50, dragStart.width - deltaX);
          newX = dragStart.xPos + deltaX;
        }
        if (resizeDirection.includes('n')) {
          newHeight = Math.max(50, dragStart.height - deltaY);
          newY = dragStart.yPos + deltaY;
        }

        onUpdate(id, { width: newWidth, height: newHeight, x: newX, y: newY });
      }
    },
    [isDragging, isResizing, dragStart, onUpdate, id, x, y, resizeDirection]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setIsResizing(false);
  }, []);

  const handleResizeStart = useCallback(
    (e) => {
      e.stopPropagation();
      setIsResizing(true);
      setResizeDirection(e.target.getAttribute('data-direction'));
      setDragStart({ x: e.clientX, y: e.clientY, width, height, xPos: x, yPos: y });
    },
    [width, height, x, y]
  );

  useEffect(() => {
    if (isDragging || isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, isResizing, handleMouseMove, handleMouseUp]);

  const handleContentChange = useCallback(() => {
    const newContent = textAreaRef.current.innerHTML;
    if (onContentChange) {
      onContentChange(id, newContent);
    } else {
      onUpdate(id, { content: newContent });
    }
  }, [id, onContentChange, onUpdate]);

  const handleDoubleClick = useCallback(() => {
    setIsEditing(true);
    focusTextArea();
  }, []);

  // Function to handle keydown events for focus management
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        setIsEditing(false);
        textAreaRef.current.blur();
      }
    },
    []
  );

  return (
    <TextBoxContainer
      style={{
        left: `${x}px`,
        top: `${y}px`,
        width: `${width}px`,
        height: `${height}px`,
      }}
      $borderStyle={currentFormatOptions.borderStyle}
      $borderWidth={currentFormatOptions.borderWidth}
      $borderColor={currentFormatOptions.borderColor}
      $isSelected={isSelected}
      $selectionColor={selectionColor}
      $isInteractive={tool === 'text' || isSelected}
      $formatOptions={currentFormatOptions}
      $isEditing={isEditing}
      onMouseDown={handleMouseDown}
      onDoubleClick={handleDoubleClick}
    >
      <TextArea
        ref={textAreaRef}
        contentEditable={isEditing}
        suppressContentEditableWarning={true}
        onInput={handleContentChange}
        onBlur={handleBlur}
        $formatOptions={currentFormatOptions}
        $isEditing={isEditing}
        onKeyDown={handleKeyDown}
      />
      {isSelected && !isEditing && (
        <>
          <ResizeHandle
            data-direction="se"
            style={{ bottom: '-6px', right: '-6px', cursor: 'se-resize' }}
            onMouseDown={handleResizeStart}
          />
          <ResizeHandle
            data-direction="sw"
            style={{ bottom: '-6px', left: '-6px', cursor: 'sw-resize' }}
            onMouseDown={handleResizeStart}
          />
          <ResizeHandle
            data-direction="ne"
            style={{ top: '-6px', right: '-6px', cursor: 'ne-resize' }}
            onMouseDown={handleResizeStart}
          />
          <ResizeHandle
            data-direction="nw"
            style={{ top: '-6px', left: '-6px', cursor: 'nw-resize' }}
            onMouseDown={handleResizeStart}
          />
        </>
      )}
    </TextBoxContainer>
  );
});

export default React.memo(TextBox);
