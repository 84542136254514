import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Toolbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
`;

const ToolbarSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.label`
  font-size: 12px;
  color: #555;
  margin-bottom: 5px;
`;

const Select = styled.select`
  padding: 5px;
  font-size: 14px;
`;

const Input = styled.input`
  padding: 5px;
  font-size: 14px;
`;

const ColorInput = styled.input`
  padding: 0;
  border: none;
  background: none;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 5px;
`;

const ToolbarButton = styled.button`
  padding: 5px 8px;
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#4299e1' : '#e2e8f0')};
  color: ${({ active }) => (active ? '#fff' : '#000')};
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: ${({ active }) => (active ? '#3182ce' : '#cbd5e0')};
  }
`;

const fonts = ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'];
const alignments = ['left', 'center', 'right', 'justify'];
const borderStyles = ['none', 'solid', 'dashed', 'dotted', 'double'];

const TextFormatToolbar = ({ options, onChange, focusTextArea }) => {
  const [localOptions, setLocalOptions] = useState(options);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  const handleOptionChange = (key, value) => {
    const updatedOptions = { ...localOptions, [key]: value };
    setLocalOptions(updatedOptions);
    onChange(updatedOptions);
  };

  return (
    <Toolbar className="toolbar">
      <ToolbarSection>
        <Label>Font:</Label>
        <Select
          value={localOptions.font}
          onChange={(e) => {
            handleOptionChange('font', e.target.value);
            focusTextArea();
          }}
        >
          {fonts.map((font) => (
            <option key={font} value={font}>
              {font}
            </option>
          ))}
        </Select>
      </ToolbarSection>

      <ToolbarSection>
        <Label>Size:</Label>
        <Input
          type="number"
          min="8"
          max="72"
          value={parseInt(localOptions.size)}
          onChange={(e) => {
            handleOptionChange('size', `${e.target.value}px`);
            focusTextArea();
          }}
        />
      </ToolbarSection>

      <ToolbarSection>
        <Label>Color:</Label>
        <ColorInput
          type="color"
          value={localOptions.color}
          onChange={(e) => {
            handleOptionChange('color', e.target.value);
            focusTextArea();
          }}
        />
      </ToolbarSection>

      <ToolbarSection>
        <Label>Style:</Label>
        <ButtonGroup>
          <ToolbarButton
            onMouseDown={(e) => {
              e.preventDefault();
              handleOptionChange('bold', !localOptions.bold);
              focusTextArea();
            }}
            active={localOptions.bold}
          >
            B
          </ToolbarButton>
          <ToolbarButton
            onMouseDown={(e) => {
              e.preventDefault();
              handleOptionChange('italic', !localOptions.italic);
              focusTextArea();
            }}
            active={localOptions.italic}
          >
            I
          </ToolbarButton>
          <ToolbarButton
            onMouseDown={(e) => {
              e.preventDefault();
              handleOptionChange('underline', !localOptions.underline);
              focusTextArea();
            }}
            active={localOptions.underline}
          >
            U
          </ToolbarButton>
        </ButtonGroup>
      </ToolbarSection>

      <ToolbarSection>
        <Label>Align:</Label>
        <Select
          value={localOptions.align}
          onChange={(e) => {
            handleOptionChange('align', e.target.value);
            focusTextArea();
          }}
        >
          {alignments.map((align) => (
            <option key={align} value={align}>
              {align.charAt(0).toUpperCase() + align.slice(1)}
            </option>
          ))}
        </Select>
      </ToolbarSection>

      <ToolbarSection>
        <Label>Line Height:</Label>
        <Input
          type="number"
          step="0.1"
          min="0.5"
          max="3"
          value={localOptions.lineHeight}
          onChange={(e) => {
            handleOptionChange('lineHeight', e.target.value);
            focusTextArea();
          }}
        />
      </ToolbarSection>

      <ToolbarSection>
        <Label>Letter Spacing:</Label>
        <Input
          type="number"
          step="0.1"
          min="0"
          max="10"
          value={localOptions.letterSpacing}
          onChange={(e) => {
            handleOptionChange('letterSpacing', e.target.value);
            focusTextArea();
          }}
        />
      </ToolbarSection>

      <ToolbarSection>
        <Label>Border Style:</Label>
        <Select
          value={localOptions.borderStyle}
          onChange={(e) => {
            handleOptionChange('borderStyle', e.target.value);
            focusTextArea();
          }}
        >
          {borderStyles.map((style) => (
            <option key={style} value={style}>
              {style.charAt(0).toUpperCase() + style.slice(1)}
            </option>
          ))}
        </Select>
      </ToolbarSection>

      <ToolbarSection>
        <Label>Border Width:</Label>
        <Input
          type="number"
          min="0"
          max="10"
          value={localOptions.borderWidth}
          onChange={(e) => {
            handleOptionChange('borderWidth', e.target.value);
            focusTextArea();
          }}
        />
      </ToolbarSection>

      <ToolbarSection>
        <Label>Border Color:</Label>
        <ColorInput
          type="color"
          value={localOptions.borderColor}
          onChange={(e) => {
            handleOptionChange('borderColor', e.target.value);
            focusTextArea();
          }}
        />
      </ToolbarSection>
    </Toolbar>
  );
};

export default TextFormatToolbar;
